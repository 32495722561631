import { ImportRegistrationsResult } from "@marketpartner/backend-api"
import { Stack, Typography } from "@mui/material"
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro"
import { keyBy } from "lodash"
import { FC, useCallback, useMemo } from "react"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"
import { ImportPreviewGrid } from "src/registrations/import/preview/ImportPreviewGrid"
import { PreparedRegistration } from "src/registrations/import/preparation/prepare-for-import"


export type ResultsGridProps = {
    registrations: PreparedRegistration[]
    columnMapping: Record<string, ColumnMatch>
    results: ImportRegistrationsResult[]
}

export const ResultsGrid: FC<ResultsGridProps> = ({
    registrations,
    columnMapping,
    results,
}) => {
    const resultsByLine = useMemo(
        () => keyBy(results, "lineNumber"),
        [results]
    )

    const erroredRows = useMemo(
        () => registrations.filter(row => resultsByLine[row.lineNumber]?.type !== "success"),
        [registrations, resultsByLine]
    )

    const getDetailPanelContent = useCallback(({ row }: any) => {
        const result = resultsByLine[row.lineNumber]
        if (!result) {
            return <ErrorPanel errors={["Network error"]} />
        }
        if (result?.type === "error") {
            return <ErrorPanel errors={result.errors} />
        }
    }, [resultsByLine])

    const detailPanelExpandedRowIds = useMemo(
        () => erroredRows.map(row => row.lineNumber),
        [erroredRows]
    )

    const columnVisibilityModel = useMemo(
        () => ({
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
        }),
        []
    )

    return <ImportPreviewGrid
        preparedRegistrations={erroredRows}
        columnMapping={columnMapping}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        columnVisibilityModel={columnVisibilityModel}
    />
}

const getDetailPanelHeight = () => 'auto' as const

type ErrorPanelProps = {
    errors: string[]
}

const ErrorPanel: FC<ErrorPanelProps> = ({
    errors
}) => {
    return <Stack sx={{ px: 1, pb: 2 }}>
        {errors.map(error => <Typography key={error} color="error">
            {error}
        </Typography>)}
    </Stack>
}