import { filterFormFields, findFormField, FormElementConfig, FormFieldConfig, RegistrationCategory, TestClass, TestTextFieldConfig } from "@marketpartner/backend-api"
import _, { isNil, keyBy, mapValues, uniq } from "lodash"


export type ExtractedFormField = {
    name: string
    configByCategory: Record<string, FormFieldConfig>
}

export class TestExtractedFormField extends TestClass implements ExtractedFormField {
    configByCategory: Record<string, FormFieldConfig>

    constructor(
        public name: string,
    ) {
        super()
        this.configByCategory = {
            "delegate": new TestTextFieldConfig(name)
        }
    }
}

export const extractFormFields = (
    categories: RegistrationCategory[],
    guestElements: FormElementConfig[],
): ExtractedFormField[] => {
    const fieldNames = uniq(filterFormFields([
        ...categories.flatMap(it => it.elements),
        ...guestElements,
    ]).map(it => it.name))

    const elementsByCategory = {
        ...mapValues(keyBy(categories, "id"), "elements"),
        "guest": guestElements,
    }

    return fieldNames.map(name => ({
        name,
        configByCategory: _(elementsByCategory)
            .mapValues(elements => findFormField(elements, name))
            .omitBy(isNil)
            .value() as Record<string, FormFieldConfig>
    }))
}
