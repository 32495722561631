import { MuiFormElementContextProvider } from "@marketpartner/mp-common-mui"
import { Box, SxProps } from "@mui/material"
import { FC } from "react"
import { asyncBackend } from "src/common/api"
import { ReorderableStack } from "src/common/reordering/ReorderableStack"
import { EditableFormElement } from "src/forms/elements/EditableFormElement"
import { useEditableFormElementsContext } from "src/forms/elements/EditableFormElementsContext"


export type WysiwygEditableFormElementsProps = {
    sx?: SxProps
}

export const WysiwygEditableFormElements: FC<WysiwygEditableFormElementsProps> = ({
    sx
}) => {
    const { reorder, onChangeElementIdx, onDeleteElementIdx } = useEditableFormElementsContext()

    return <Box sx={sx}>
        <MuiFormElementContextProvider
            backend={asyncBackend}
            showHiddenFields
            validationEnabled
            dense
        >
            <ReorderableStack
                items={reorder.orderedItems}
                onReorder={reorder.reorderItems}
                reorderEnabled={reorder.isReordering}
                loading={reorder.isLoading}
                renderItem={state => <EditableFormElement
                    state={state}
                    onChangeElement={onChangeElementIdx(state.idx)}
                    onDelete={onDeleteElementIdx(state.idx)}
                    disableEdit={reorder.isReordering}
                />}
                spacing={1}
                sx={{
                    mx: "auto",
                    maxWidth: 800,
                    py: 1,
                }}
            />
        </MuiFormElementContextProvider >
    </Box>
}