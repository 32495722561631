import { Role } from "@marketpartner/backend-api";
import { link } from "src/common/routing/routes";
import { LoginContext } from "src/login/LoginContext";

export type CustomUiRole = {
    defaultPage: (login: LoginContext) => string,
    showSidebar: boolean
}

export const customUiRoles: Partial<Record<Role, CustomUiRole>> = {
    [Role.Moderator]: {
        defaultPage: login => link.toModeration(login.singleClientId!, login.singleEventId!),
        showSidebar: false
    },
    [Role.EventContentEditor]: {
        defaultPage: login => link.toContentEditor(login.singleClientId!, login.singleEventId!),
        showSidebar: false
    }
}