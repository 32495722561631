import { ScheduledEmailBooleanExpression } from "@marketpartner/backend-api"
import { pluralizeCount } from "@marketpartner/mp-common"
import { Skeleton } from "@mui/lab"
import { Box, SxProps } from "@mui/material"
import { FC } from "react"
import { useRegistrationConditionCount } from "src/email/scheduling/useRegistrationConditionCount"


export type RegistrationConditionCountProps = {
    expression: ScheduledEmailBooleanExpression
    sx?: SxProps
}

export const RegistrationConditionCount: FC<RegistrationConditionCountProps> = ({
    expression,
    sx,
}) => {
    const registrationCount = useRegistrationConditionCount(expression)

    return <Box sx={sx}>
        {registrationCount === undefined ?
            <Skeleton width={100} /> :
            pluralizeCount(registrationCount, "registration")
        }
    </Box>
}