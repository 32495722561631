import { ComputedColumnValues, FormResponse, FormResponseDisplayStatus, Permission, StandardForm } from "@marketpartner/backend-api"
import { Button, Checkbox, FormControlLabel, Paper } from "@mui/material"
import DownloadIcon from '@mui/icons-material/Download'
import { Box } from "@mui/system"
import { FC, useMemo, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useExportMenu } from "src/common/grid/ExportMenu"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { fullSize } from "src/common/styles"
import { useEvent } from "src/events/event-context"
import { buildFormResponsesColumns, FormResponsesGrid } from "src/forms/responses/FormResponsesGrid"
import { useLoginContext } from "src/login/LoginContext"

const extraExportFields = [
    { field: 'title', headerName: 'Title' },
    { field: 'firstName', headerName: 'First name' },
    { field: 'lastName', headerName: 'Last name' },
    { field: 'email', headerName: 'Email' },
    { field: 'categoryId', headerName: 'Category' }
]

export type FormResponsesProps = {
    form: StandardForm
}

export type FormResponseWithComputedValues = FormResponse & { computedValues: ComputedColumnValues }

export const FormResponses: FC<FormResponsesProps> = ({
    form
}) => {
    const client = useClient()!
    const event = useEvent()!
    const login = useLoginContext()

    const [showHiddenResponses, setShowHiddenResponses] = useState(false)

    const computedColumnValuesQuery = backend.computedColumns.useGetAllComputedColumnValues([client.id, event.id])
    const formResponsesQuery = backend.formResponses.useGetAll([client.id, event.id, form.id], {
        select: responses => responses.filter(it =>
            showHiddenResponses ||
            it.displayStatus !== FormResponseDisplayStatus.Hidden
        ).map(response => ({
            ...response,
            computedValues: computedColumnValuesQuery.data?.[response.registrationId!] ?? {}
        })) as FormResponseWithComputedValues[],
        refetchInterval: 6000,
    })

    const canDeleteResponses = login.hasPermission(Permission.DeleteForms, client.id, event.id)
    const canModerate = form.isModerated && login.hasPermission(Permission.ModerateFormResponses, client.id, event.id)
    const columns = useMemo(
        () => buildFormResponsesColumns(form.visibleRegFields, form.visibleComputedColumns, formResponsesQuery.data ?? [], canModerate, canDeleteResponses),
        [form.visibleRegFields, form.visibleComputedColumns, formResponsesQuery.data, canModerate, canDeleteResponses]
    )

    const exportMenu = useExportMenu()

    return <BarAndFlex barContent={
        <Bar p={1}>
            {form.isModerated && <FormControlLabel
                label="Show hidden responses"
                control={<Checkbox
                    checked={showHiddenResponses}
                    onChange={e => setShowHiddenResponses(e.target.checked)}
                />}
            />}
            <Box sx={{ mx: "auto" }} />
            <Box sx={{ mx: 2 }}>
                <Box sx={{ paddingBottom: '10px' }}>
                    <Button
                        disabled={!formResponsesQuery.data}
                        onClick={e => exportMenu.open({
                            defaultColumns: [
                                ...extraExportFields,
                                ...columns
                            ],
                            data: formResponsesQuery.data!,
                            //TODO: shouldn't need to use substring here
                            name: `${form.name.substring(0, 20)}-responses`,
                            anchorEl: e.currentTarget,
                        })}
                        color="primary"
                        endIcon={<DownloadIcon/>}
                        variant="contained"
                    >
                        Export
                    </Button>
                </Box>
            </Box>
        </Bar>
    }>
        <Paper sx={{ ...fullSize }}>
            <FormResponsesGrid responsesQuery={formResponsesQuery} columns={columns} />
        </Paper>
    </BarAndFlex>
}
