import { EventDetails, filterFormFields, RegistrationCategoryType, StandardForm } from "@marketpartner/backend-api"
import { filterType, toUtcDateTime } from "@marketpartner/mp-common"
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material"
import _ from "lodash"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { useClientId } from "src/clients/client-context"
import { backend } from "src/common/api"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { Loading } from "src/common/loading/Loading"
import { ActiveStatusEditor } from "src/common/statuses/ActiveStatusEditor"
import { useEvent } from "src/events/event-context"
import { formStatusDetails } from "src/forms/form-status-details"

const defaultSelectedVisibleRegFields = ['name']

const defaultRegFields = [
    { name: 'name', label: 'Name' }
]

export type FormFormData = {
    name: string
    start: DateTime | null
    end: DateTime | null
    isModerated: boolean
    allowAnonymousResponses: boolean
    visibleRegFields: string[]
    visibleComputedColumns: string[]
}

export type FormFormProps = ExtendableDialogFormProps<FormFormData> & {
    form?: StandardForm
}

export const FormForm: FC<FormFormProps> = ({
    form,
    ...props
}) => {
    const [name, setName] = useState(form?.name ?? "")
    const [start, setStart] = useState(form?.start ?? null)
    const [end, setEnd] = useState(form?.end ?? null)
    const [isModerated, setIsModerated] = useState(form?.isModerated ?? false)
    const [allowAnonymousResponses, setAllowAnonymousResponses] = useState(form?.allowAnonymousResponses ?? false)
    const [visibleRegFields, setVisibleRegFields] = useState(form?.visibleRegFields ?? defaultSelectedVisibleRegFields)
    const [visibleComputedColumns, setVisibleComputedColumns] = useState<string[]>(form?.visibleComputedColumns ?? [])
    const clientId = useClientId()!
    const event = useEvent()!

    function addVisibleRegField(name: string) {
        setVisibleRegFields(prevItems => prevItems.includes(name) ? [...prevItems] : [...prevItems, name])
    }

    function removeVisibleRegField(name: string) {
        setVisibleRegFields(prevItems => prevItems.filter(item => item !== name))
    }

    function resetVisibleRegFields() {
        setVisibleRegFields(defaultSelectedVisibleRegFields)
    }

    function addVisibleComputedColumn(name: string) {
        setVisibleComputedColumns(prevItems => prevItems.includes(name) ? [...prevItems] : [...prevItems, name])
    }
    function removeVisibleComputedColumn(name: string) {
        setVisibleComputedColumns(prevItems => prevItems.filter(item => item !== name))
    }
    function resetVisibleComputedColumns() {
        setVisibleComputedColumns([])
    }

    const regElementsRequest = backend.registrationCategories.useGetAll([clientId, event.id], {
        select: forms => _(filterType(forms, RegistrationCategoryType.Delegate))
            .flatMap(form => filterFormFields(form.elements))
            .uniqBy("name")
            .value()
    })
    const computedColumnsRequest = backend.events.useGetEvent([clientId, event.id], {
        select: event => (event as EventDetails).computedColumns
    })

    return <DialogForm
        formData={{
            name,
            start: toUtcDateTime(start),
            end: toUtcDateTime(end),
            isModerated,
            allowAnonymousResponses,
            visibleRegFields,
            visibleComputedColumns
        }}
        {...props}
    >
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <ActiveStatusEditor
                    details={formStatusDetails}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                    timezone={event.timezone}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    label="Is moderated?"
                    control={<Checkbox
                        color="primary"
                        checked={isModerated}
                        onChange={e => setIsModerated(e.target.checked)}
                    />}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    label="Allow anonymous responses?"
                    control={<Checkbox
                        color="primary"
                        checked={allowAnonymousResponses}
                        onChange={e => setAllowAnonymousResponses(e.target.checked)}
                    />}
                />
            </Grid>
        </Grid>
        <Loading request={regElementsRequest} sizing="fit-content" render={regFields => {
            const allRegFields = [...defaultRegFields, ...regFields]
            const allRegFieldsIds = allRegFields.map(f => f.name)
            return <>
                <Grid container spacing={2} alignItems="center" sx={{ padding: '20px 0' }}>

                    <Grid item xs={12}><Typography variant="h6">Registration fields to include:</Typography></Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={resetVisibleRegFields}
                        >
                            Set to default
                        </Button>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => setVisibleRegFields(allRegFieldsIds)}
                        >
                            Select all
                        </Button>
                    </Grid>
                    {allRegFields.map((regField, index) => <Grid key={index} item xs={3}>
                        <FieldCheckbox
                            label={"label" in regField ? regField.label : regField.name}
                            checked={visibleRegFields.includes(regField.name)}
                            onCheck={() => addVisibleRegField(regField.name)}
                            onUncheck={() => removeVisibleRegField(regField.name)}
                        />
                    </Grid>)}
                </Grid>
            </>
        }} />
        <Loading request={computedColumnsRequest} sizing="fit-content" render={computedColumns => {
            return <>
                <Grid container spacing={2} alignItems="center" sx={{ padding: '20px 0' }}>
                    <Grid item xs={12}><Typography variant="h6">Computed columns to include:</Typography></Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={resetVisibleComputedColumns}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => setVisibleComputedColumns(computedColumns.map(it => it.id))}
                        >
                            Select all
                        </Button>
                    </Grid>
                    {computedColumns?.map((column, index) => <Grid key={index} item xs={3}>
                        <FieldCheckbox
                            label={column.id}
                            checked={visibleComputedColumns.includes(column.id)}
                            onCheck={() => addVisibleComputedColumn(column.id)}
                            onUncheck={() => removeVisibleComputedColumn(column.id)}
                        />
                    </Grid>)}
                </Grid>
            </>
        }} />

    </DialogForm>
}

type FieldCheckboxProps = {
    label: string
    checked: boolean
    onCheck: () => void
    onUncheck: () => void
}

const FieldCheckbox: FC<FieldCheckboxProps> = ({
    label,
    checked,
    onCheck,
    onUncheck
}) => {
    return <FormControlLabel
        label={label}
        control={<Checkbox
            color="primary"
            checked={checked}
            onChange={e => {
                if (e.target.checked) {
                    onCheck()
                } else {
                    onUncheck()
                }
            }}
        />}
    />
}