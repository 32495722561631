import { RegistrationElementMetadata } from "@marketpartner/backend-api"
import { MuiFormElements } from "@marketpartner/mp-common-mui"
import { DynamicFormState } from "@marketpartner/mp-common-react"
import { Check, Clear, Edit, Error } from "@mui/icons-material"
import { Button, Collapse, Stack } from "@mui/material"
import { FC } from "react"
import { asyncBackend } from "src/common/api"
import { BasicTabs } from "src/common/routing/BasicTabs"

export type RegistrationFormsProps = {
    fields: DynamicFormState<RegistrationElementMetadata>
    profileFields: DynamicFormState
    profileFormEnabled: boolean
    onChangeProfileFormEnabled: (enabled: boolean) => void
}

export const RegistrationForms: FC<RegistrationFormsProps> = ({
    fields,
    profileFields,
    profileFormEnabled,
    onChangeProfileFormEnabled,
}) => {

    return <BasicTabs sizing="fit-content" variant="fullWidth" tabs={[
        {
            label: "Registration",
            icon: fields.invalidFields.length === 0 ?
                <Check color="success" /> :
                <Error color="error" />,
            iconPosition: "end",
            sx: { minHeight: 0 },
            render: () => <MuiFormElements
                showHiddenFields
                state={fields}
                backend={asyncBackend}
                validationEnabled
                allowEditingUrlParameters
                sx={{ my: 1 }}
            />
        },
        {
            label: "Profile",
            icon: !profileFormEnabled ? <Clear color="disabled" /> :
                profileFields.invalidFields.length === 0 ? <Check color="success" /> :
                    <Error color="error" />,
            iconPosition: "end",
            sx: { minHeight: 0 },
            render: () => <Stack sx={{ alignItems: "center", my: 2 }}>
                <Collapse in={!profileFormEnabled}>
                    <Button
                        onClick={() => onChangeProfileFormEnabled(true)}
                        startIcon={<Edit />}
                        children="Fill profile form"
                        sx={{ mt: 2 }}
                    />
                </Collapse>

                <Collapse in={profileFormEnabled}>
                    <MuiFormElements
                        showHiddenFields
                        state={profileFields}
                        backend={asyncBackend}
                        validationEnabled
                        allowEditingUrlParameters
                    />
                    <Button
                        onClick={() => onChangeProfileFormEnabled(false)}
                        startIcon={<Clear />}
                        children="Clear profile form"
                        color="error"
                        sx={{ mt: 2 }}
                    />
                </Collapse>
            </Stack>
        }
    ]} />
}