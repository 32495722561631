import { NullableSelect } from "@marketpartner/mp-common-mui"
import { MenuItem, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, useCallback } from "react"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"
import { ExtractedFormField } from "src/registrations/import/pre-process/extract-form-fields"


export type ColumnMatchSelectorProps = {
    match: ColumnMatch | null
    onIgnore: () => void
    onMatch: (formFieldName: string) => void
    formFields: ExtractedFormField[]
}

const ignoredValue = "__IGNORED_CSV_COLUMN__"
const getSelectValue = (match: ColumnMatch | null) => {
    switch (match?.type) {
        case "field": return match.formField.name
        case "ignored": return ignoredValue
        default: return ""
    }
}

export const ColumnMatchSelector: FC<ColumnMatchSelectorProps> = ({
    match,
    onMatch,
    onIgnore,
    formFields,
}) => {
    const handleChange = useCallback((selectValue: string | null) => {
        if (selectValue === null) {
            return
        }
        if (selectValue === ignoredValue) {
            onIgnore()
        } else {
            onMatch(selectValue)
        }
    }, [onMatch, onIgnore])

    if (match && typeof match === "object" && "coreColumn" in match) {
        return <Stack>
            <Typography>
                {match.coreColumn}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                (system defined)
            </Typography>
        </Stack>
    }

    const selectValue = getSelectValue(match)

    return <NullableSelect
        required
        value={selectValue}
        onChange={handleChange}
        size="small"
        placeholder="Select form field"
        sx={{
            minWidth: 150,
            maxWidth: 250,
        }}
    >
        <MenuItem key={ignoredValue} value={ignoredValue}>
            Ignored
        </MenuItem>
        {formFields.map(field => <MenuItem key={field.name} value={field.name}>
            <Stack>
                <Typography>
                    {field.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {Object.keys(field.configByCategory).join(", ")}
                </Typography>
            </Stack>
        </MenuItem>)}
    </NullableSelect>
}