import { RegistrationCategory, RegistrationCategoryType, Scope } from "@marketpartner/backend-api"
import { getErrorString } from "@marketpartner/mp-common"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog"
import { createPopupHook } from "src/common/dialogs/Popup"
import { withNotification } from "src/common/notifications/with-notification"
import { useEvent } from "src/events/event-context"
import { PrimaryRegistrationDialogTitle } from "src/registrations/actions/PrimaryRegistrationDialogTitle"
import { PrimaryRegistrationForms } from "src/registrations/actions/PrimaryRegistrationForms"

export type CreatePrimaryRegistrationDialogProps = LockableDialogProps & CreatePrimaryRegistrationDialogContentProps

const CreatePrimaryRegistrationDialog: FC<CreatePrimaryRegistrationDialogProps> = ({
    initialCategory,
    restrictCategoryType,
    ...props
}) => {
    return <LockableDialog fullWidth maxWidth="md" {...props}>
        <CreatePrimaryRegistrationDialogContent initialCategory={initialCategory} restrictCategoryType={restrictCategoryType} />
    </LockableDialog>
}

type CreatePrimaryRegistrationDialogContentProps = {
    initialCategory?: RegistrationCategory
    restrictCategoryType?: RegistrationCategoryType
}

const CreatePrimaryRegistrationDialogContent: FC<CreatePrimaryRegistrationDialogContentProps> = ({
    initialCategory,
    restrictCategoryType
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [category, setCategory] = useState<RegistrationCategory | null>(initialCategory ?? null)
    const createMutation = backend.registrations.useCreatePrimaryRegistration(withNotification({
        successMessage: "Created registration",
        errorMessage: (e) => getErrorString(e),
    }))

    return <>
        <PrimaryRegistrationDialogTitle
            initialCategoryId={initialCategory?.id}
            onSelectCategory={setCategory}
            children="Create registration"
            restrictCategoryType={restrictCategoryType}
        />
        <PrimaryRegistrationForms
            onSubmit={formData => createMutation.mutateAsync([client.id, event.id, formData])}
            category={category}
            actionName="Create registration"
        />
    </>
}

export const useCreatePrimaryRegistrationDialog = createPopupHook({
    scope: Scope.Event,
    element: CreatePrimaryRegistrationDialog,
})