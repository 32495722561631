import { Role, Scope } from "@marketpartner/backend-api";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { ClientSelector } from "src/clients/ClientSelector";
import { EventSelector } from "src/events/EventSelector";

export type RoleSelectorProps = {
    clientId: string | null,
    onChangeClientId: (clientId: string | null) => void,
    eventId: string | null,
    onChangeEventId: (eventId: string | null) => void,
    excludedRoles?: Role[],
    sx?: any
} & ({
    required: true
    role: Role,
    onChangeRole: (role: Role) => void,
} | {
    required?: false
    role: Role | null,
    onChangeRole: (role: Role | null) => void,
})

export const RoleSelector: FC<RoleSelectorProps> = ({
    excludedRoles,
    role,
    onChangeRole,
    clientId,
    onChangeClientId,
    eventId,
    onChangeEventId,
    sx
}) => {


    function changeRole(newRole: Role) {
        if (!requiresEvent(newRole)) {
            onChangeEventId(null)
        }
        if (!requiresClient(newRole)) {
            onChangeClientId(null)
        }
        onChangeRole(newRole)
    }

    function changeClient(newClient: string | null) {
        onChangeEventId(null)
        onChangeClientId(newClient)
    }

    return <Grid container spacing={2} sx={sx}>
        <Grid item xs={6}>
            <RoleSelect excludedRoles={excludedRoles} role={role} onChangeRole={changeRole as any} />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
            {requiresClient(role) &&
                <ClientSelector clientId={clientId} onSelectClientId={changeClient} required fullWidth />
            }
        </Grid>
        <Grid item xs={6}>
            {requiresEvent(role) && clientId &&
                <EventSelector clientId={clientId} selectedId={eventId} onSelectId={onChangeEventId} required fullWidth />
            }
        </Grid>
    </Grid>;
}

function requiresClient(role: Role | null) {
    return role && roleRestrictions[role] !== Scope.Global
}

function requiresEvent(role: Role | null) {
    return role && roleRestrictions[role] === Scope.Event
}

const roleRestrictions: Record<Role, Scope> = {
    Admin: Scope.Global,
    ClientAdmin: Scope.Client,
    ClientUser: Scope.Client,
    EventAdmin: Scope.Event,
    EventUser: Scope.Event,
    Moderator: Scope.Event,
    ClientContentEditor: Scope.Client,
    EventContentEditor: Scope.Event,
    RegisteredUser: Scope.Event,
    Scanner: Scope.Event,
    GlobalMailer: Scope.Global,
}

type RoleSelectProps = {
    excludedRoles?: Role[],
    role: Role | null,
    onChangeRole: (role: Role | null) => void,
}

const RoleSelect: FC<RoleSelectProps> = ({
    excludedRoles,
    role,
    onChangeRole
}) => {
    const roles = Object.keys(roleRestrictions)
        .filter(r => !excludedRoles || !excludedRoles.includes(r as Role))
    return <FormControl fullWidth>
        <InputLabel id="role-label">Role</InputLabel>
        <Select
            name="role"
            label="Role"
            labelId="role-label"
            value={role ?? ""}
            onChange={e => onChangeRole(e.target.value as Role)}
            required
        >
            {roles.map(role => <MenuItem key={role} value={role}>
                {role}
            </MenuItem>
            )}
        </Select>
    </FormControl>;
}
