import { evaluateScheduledEmailBooleanExpression, Registration, RegistrationType, ScheduledEmailBooleanExpression } from "@marketpartner/backend-api"
import { filterType } from "@marketpartner/mp-common"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"


export const useRegistrationConditionCount = (
    expression: ScheduledEmailBooleanExpression | null,
) => {
    const client = useClient()!
    const event = useEvent()!

    const registrationCount = backend.registrations.useGetAll([client.id, event.id], {
        select: registrations => filterType(registrations as Registration[], RegistrationType.Primary)
            .filter(registration => !expression || evaluateScheduledEmailBooleanExpression(expression, registration))
            .length,
    })

    return registrationCount.data
}