import { DataGridPro, DataGridProProps, GridColDef } from "@mui/x-data-grid-pro"
import { FC, useMemo } from "react"
import { convertToSpreadSheetString } from "src/common/grid/grid-export"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"
import { ImportActionDetails } from "src/registrations/import/preview/ImportActionDetails"
import { PreparedRegistration } from "src/registrations/import/preparation/prepare-for-import"

export type ImportPreviewGridProps = Partial<DataGridProProps> & {
    preparedRegistrations: PreparedRegistration[]
    columnMapping: Record<string, ColumnMatch>
}

export const ImportPreviewGrid: FC<ImportPreviewGridProps> = ({
    preparedRegistrations,
    columnMapping,
    ...gridProps
}) => {
    const columns = useMemo(() => [
        actionColumn,
        ...buildColumns(columnMapping),
    ], [columnMapping])

    return <DataGridPro
        columns={columns}
        rows={preparedRegistrations}
        getRowId={getRowId}
        disableRowSelectionOnClick
        disableColumnMenu
        {...gridProps}
    />
}

const getRowId = (registration: PreparedRegistration) => registration.lineNumber

const actionColumn: GridColDef<PreparedRegistration> = {
    field: "importAction",
    headerName: "Import action",
    valueGetter: ({ row }) => row.existingRegistration,
    renderCell: ({ value }) => <ImportActionDetails existingRegistration={value} />,
    width: 250,
}

const buildColumns = (
    columnMapping: Record<string, ColumnMatch>
): GridColDef<PreparedRegistration>[] => Object.entries(columnMapping)
    .filter(([_csvColumn, match]) => match.type !== "ignored")
    .filter(([_csvColumn, match]) => match.type !== "coreColumn" || match.coreColumn !== "id")
    .map(([csvColumn, match]) => ({
        field: csvColumn,
        headerName: headerName(match),
        valueGetter: ({ row }) => valueGetter(row, match),
    }))

const headerName = (match: ColumnMatch) => {
    switch (match.type) {
        case "coreColumn":
            return match.coreColumn
        case "field":
            return match.formField.name
        case "ignored":
            return "Ignored"
    }
}

const valueGetter = (
    preparedRegistration: PreparedRegistration,
    match: ColumnMatch,
) => {
    if (match.type === "ignored") {
        return ""
    }
    if (match.type === "field") {
        const fieldValue = preparedRegistration.fields[match.formField.name]
        return convertToSpreadSheetString(fieldValue)
    }
    if (match.coreColumn === "email") {
        return preparedRegistration.fields["email"]
    }
    return (preparedRegistration as any)[match.coreColumn] as string
}