import { FormResponse } from "@marketpartner/backend-api"
import { casualName } from "@marketpartner/mp-common"
import { Typography } from "@mui/material"
import { GridColDef, GridSortItem } from "@mui/x-data-grid-pro"
import { UseQueryResult } from "@tanstack/react-query"
import { FC, useState } from "react"
import { ColumnCell, getDynamicColumnDefinitions } from "src/common/grid/dynamic-columns"
import { ExportColumnDefinition } from "src/common/grid/grid-export"
import { QueryDataGrid } from "src/common/grid/QueryDataGrid"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { DeleteFormResponseButton } from "src/forms/responses/DeleteFormResponseButton"
import { FormResponseWithComputedValues } from "src/forms/responses/FormResponses"
import { HighlightResponseButton } from "src/forms/responses/HighlightResponseButton"
import { ResponseStatusButtons } from "src/forms/responses/ResponseStatusButtons"

export type FormResponsesGridProps = {
    responsesQuery: UseQueryResult<FormResponse[]>
    columns: GridColDef<FormResponse>[]
}

export const FormResponsesGrid: FC<FormResponsesGridProps> = ({ responsesQuery, columns }) => {
    const [sortModel, setSortModel] = useState<GridSortItem[]>([
        { field: "timeInserted", sort: "desc" }
    ])

    return <QueryDataGrid
        queryOrRows={responsesQuery}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        disableRowSelectionOnClick
    />
}

export function buildFormResponsesColumns(
    visibleRegFields: string[],
    visibleComputedColumns: string[],
    responses: FormResponseWithComputedValues[],
    canModerate: boolean,
    canDeleteResponses: boolean
): ExportColumnDefinition<FormResponse>[] {
    const showName = visibleRegFields.includes('name')

    const nameCol: ExportColumnDefinition<FormResponse> = {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 200,
        valueGetter: ({ row }) => `${row.lastName} ${row.firstName}`,
        getCsvValue: ({ row }) => casualName(row),
        renderCell: ({ row }) => casualName(row) === "Anonymous" ?
            <Typography color="text.secondary">Anonymous</Typography> :
            <Typography><ColumnCell value={casualName(row)} /></Typography>,
        excludeFromCsv: true
    }

    const highlightCol: ExportColumnDefinition<FormResponse> = {
        field: "isHighlighted",
        headerName: "Highlight",
        sortable: true,
        renderCell: ({ row }) => <HighlightResponseButton
            isHighlighted={row.isHighlighted}
            formId={row.formId}
            formResponseId={row.id}
        />
    }
    const statusCol: ExportColumnDefinition<FormResponse> = {
        field: "displayStatus",
        headerName: "Display status",
        width: 120,
        sortable: true,
        renderCell: ({ row }) => <ResponseStatusButtons
            displayStatus={row.displayStatus}
            formId={row.formId}
            formResponseId={row.id}
        />
    }
    const deleteCol: ExportColumnDefinition<FormResponse> = {
        field: "actions",
        headerName: "Delete",
        sortable: true,
        renderCell: params => <DeleteFormResponseButton
            formId={params.row.formId}
            formResponseId={params.row.id}
        />,
        excludeFromCsv: true,
    }

    return [
        ...showName ? [nameCol] : [],
        {
            field: "timeInserted",
            headerName: "Time inserted",
            flex: 1,
            minWidth: 100,
            renderCell: ({ value }) => <EventDateTimeText dateTime={value} />
        },
        ...canModerate ? [highlightCol, statusCol] : [],
        ...canDeleteResponses ? [deleteCol] : [],
        ...getDynamicColumnDefinitions(responses, "formData"),
        ...getDynamicColumnDefinitions(responses, "fields", {
            headerPrefix: "reg-",
            filter: columnName => visibleRegFields.includes(columnName),
        }),
        ...getDynamicColumnDefinitions(responses, "computedValues", {
            headerPrefix: "computed-",
            filter: columnName => visibleComputedColumns.includes(columnName),
        }),
    ]
}