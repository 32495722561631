import { Permission, PrimaryRegistration, ProfileVisibilityLevel, RegistrationCategory, RegistrationCategoryType, RegistrationType, reorderBody } from "@marketpartner/backend-api"
import { filterType } from "@marketpartner/mp-common"
import { Public } from "@mui/icons-material"
import { Collapse, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { StickyHeadingAndContent } from "src/common/layout/StickyHeadingAndContent"
import { Loading } from "src/common/loading/Loading"
import { ContextMenuButton } from "src/common/menus/ContextMenuButton"
import { ReorderableStack } from "src/common/reordering/ReorderableStack"
import { ReorderButtons } from "src/common/reordering/ReorderButtons"
import { useBulkReorder } from "src/common/reordering/useBulkReorder"
import { AddContentCard } from "src/content/AddContentCard"
import { useSpeakerCategoryMenu } from "src/content/speakers/SpeakerCategoryContextMenu"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { useCreatePrimaryRegistrationDialog } from "src/registrations/actions/CreatePrimaryRegistrationDialog"
import { ProfileCard } from "src/registrations/profiles/ProfileCard"
import { useProfileContextMenu } from "src/registrations/profiles/ProfileContextMenu"


export type EditableSpeakerCategoryProps = {
    category: RegistrationCategory
    collapse?: boolean
}

export const EditableSpeakerCategory: FC<EditableSpeakerCategoryProps> = ({
    category,
    collapse = false,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const speakersQuery = backend.registrations.useGetAll([client.id, event.id, { categoryType: RegistrationCategoryType.Speaker }], {
        select: registrations => filterType(registrations, RegistrationType.Primary as const)
            .filter(it => it.categoryId === category.id) as PrimaryRegistration[]
    })
    const reorderMutation = backend.registrations.useReorder()
    const reorder = useBulkReorder({
        items: speakersQuery.data ?? [],
        onReorder: items => reorderMutation.mutateAsync([client.id, event.id, reorderBody(items)])
    })
    const menu = useSpeakerCategoryMenu()
    const createRegistrationDialog = useCreatePrimaryRegistrationDialog()
    const contextMenu = useProfileContextMenu()

    return <StickyHeadingAndContent headingContent={<Bar>
        <Typography variant="h6" children={category.pluralName} />
        {category.profileVisibilityLevel === ProfileVisibilityLevel.Public && <PublicIcon />}
        <Spacer />
        <ReorderButtons
            reorder={reorder}
            reorderButtonProps={{
                sx: { display: "none" }
            }}
        />
        {ifHas(Permission.EditEventContent, client.id, event.id, <ContextMenuButton
            onClick={e => menu.open({
                category,
                anchorEl: e.currentTarget,
                onStartReorder: reorder.startReorder,
            })}
            disabled={reorder.isReordering || reorder.isLoading}
            tone="medium"
        />)}
    </Bar>}>
        <Collapse in={!collapse}>
            <Loading request={speakersQuery} sizing="fit-content" render={() =>
                <ReorderableStack
                    variant="grid"
                    reorderEnabled={reorder.isReordering}
                    items={reorder.orderedItems}
                    onReorder={reorder.reorderItems}
                    loading={reorder.isLoading}
                    renderItem={speaker => <ProfileCard
                        registration={speaker}
                        onClickMenu={e => contextMenu.open({
                            registration: speaker,
                            anchorEl: e.currentTarget,
                            restrictCategoryType: RegistrationCategoryType.Speaker
                        })}
                    />}
                    addItem={<AddContentCard
                        onClick={() => createRegistrationDialog.open({
                            initialCategory: category,
                            restrictCategoryType: RegistrationCategoryType.Speaker
                        })}
                    />}
                    spacing={1}
                    useFlexGap
                />}
            />
        </Collapse>
    </StickyHeadingAndContent >
}

const PublicIcon: FC = () => <Bar ml={2} color="text.secondary">
    <Public fontSize="small" />
    <Typography variant="subtitle2" children="Public" />
</Bar>
