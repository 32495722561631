import { FormData, GuestRegistration } from "@marketpartner/backend-api"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { FC, useState } from "react"
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm"
import { useEvent } from "src/events/event-context"
import { RegistrationForms } from "src/registrations/actions/RegistrationForms"


export type GuestFormsData = {
    fields: FormData
    profileFields: FormData | null
}

export type GuestFormsProps = ExtendableDialogFormProps<GuestFormsData> & {
    registration?: GuestRegistration
}

export const GuestForms: FC<GuestFormsProps> = ({
    registration,
    ...props
}) => {
    const event = useEvent()!
    const [profileFormEnabled, setProfileFormEnabled] = useState(Boolean(registration?.profileFields))

    const fields = useDynamicFormState({
        elements: event.guestElements ?? [],
        requirement: "complete",
        initialValues: registration?.fields,
    })
    const profileFields = useDynamicFormState({
        elements: event.guestProfileElements ?? [],
        requirement: "complete",
        initialValues: registration?.profileFields ?? undefined,
    })

    return <DialogForm
        formData={{
            fields: fields.values,
            profileFields: profileFormEnabled ? profileFields.values : null,
        }}
        dialogContentStyle={{ py: 0 }}
        isValid={fields.invalidFields.length === 0 && (!profileFormEnabled || profileFields.invalidFields.length === 0)}
        {...props}
    >
        <RegistrationForms
            fields={fields}
            profileFields={profileFields}
            profileFormEnabled={profileFormEnabled}
            onChangeProfileFormEnabled={setProfileFormEnabled}
        />
    </DialogForm>
}