import { CsvFormFieldValue, EnhancedUploadCsvValue, fromCsvValue, IncomingEnhancedUpload, IncomingFormData, Registration, RegistrationType, TestClass } from "@marketpartner/backend-api"
import { chunk, pick } from "lodash"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"
import { ValidMergedRegistration } from "src/registrations/import/pre-process/validate-core-properties"

const parallelism = 4

type BasePreparedRegistration = {
    lineNumber: number
    parsedId?: string
    existingRegistration?: Registration
    fields: IncomingFormData
}

export type PreparedPrimaryRegistration = BasePreparedRegistration & {
    type: RegistrationType.Primary
    categoryId: string
}
export type PreparedGuest = BasePreparedRegistration & {
    type: RegistrationType.Guest
    primaryRegistrationId: string
}

export type PreparedRegistration = PreparedPrimaryRegistration | PreparedGuest

export class TestPreparedPrimaryRegistration extends TestClass implements PreparedPrimaryRegistration {
    type = RegistrationType.Primary as const
    lineNumber = 0
    parsedId?: string
    existingRegistration?: Registration
    fields: IncomingFormData = {}
    categoryId = "delegate"
}

export class TestPreparedGuest extends TestClass implements PreparedGuest {
    type = RegistrationType.Guest as const
    lineNumber = 0
    parsedId?: string
    existingRegistration?: Registration
    fields: IncomingFormData = {}
    primaryRegistrationId = "primary"
}

export type CsvFileUploadHandler = (csvValue: EnhancedUploadCsvValue) => Promise<IncomingEnhancedUpload>

export const prepareForImport = async (
    registrations: ValidMergedRegistration[],
    columnMapping: Record<string, ColumnMatch>,
    uploadHandler: CsvFileUploadHandler,
    progressListener?: (progress: number) => void
): Promise<PreparedRegistration[]> => {
    const prepared: PreparedRegistration[] = []
    const batches = chunk(registrations, parallelism)
    await Promise.all(batches.map(async batch => {
        for (const registration of batch) {
            prepared.push(await prepare(registration, columnMapping, uploadHandler))
            progressListener?.(prepared.length / registrations.length)
        }
    }))
    return prepared
}

const prepare = async (
    registration: ValidMergedRegistration,
    columnMapping: Record<string, ColumnMatch>,
    uploadHandler: CsvFileUploadHandler,
): Promise<PreparedRegistration> => {
    const commonProps = {
        ...pick(registration, "lineNumber", "parsedId", "existingRegistration"),
        fields: await transformFields(registration.fields, columnMapping, registration.categoryId, uploadHandler)
    }

    if (registration.type === RegistrationType.Primary) {
        return {
            ...commonProps,
            type: RegistrationType.Primary,
            categoryId: registration.categoryId
        }
    }
    return {
        ...commonProps,
        type: RegistrationType.Guest,
        primaryRegistrationId: registration.primaryRegistrationId,
    }
}

const transformFields = async (
    fields: Record<string, string>,
    columnMapping: Record<string, ColumnMatch>,
    categoryId: string | undefined,
    uploadHandler: CsvFileUploadHandler,
): Promise<IncomingFormData> => {
    const transformed: IncomingFormData = {}
    for (const [csvColumn, value] of Object.entries(fields)) {
        const mapping = columnMapping[csvColumn]
        if (mapping?.type === "field") {
            const config = mapping.formField.configByCategory[categoryId ?? "guest"]
            if (config) {
                const csvValue = value && fromCsvValue(value, config)
                if (typeof csvValue === 'object' && 'type' in csvValue && csvValue.type === "CsvUploadedFileUrl") {
                    transformed[mapping.formField.name] = await uploadHandler(csvValue)
                } else {
                    // Not sure why TS needs this cast
                    transformed[mapping.formField.name] = csvValue as Exclude<CsvFormFieldValue, EnhancedUploadCsvValue>
                }
            }
        }
    }
    return transformed
}
