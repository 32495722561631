import { ContentEntity, Scope, entityContentTypeLookup } from "@marketpartner/backend-api";
import { DeleteForever, Edit, MoveDown, OpenInNew } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC, ReactElement } from "react";
import { useClientId } from "src/clients/client-context";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { link } from "src/common/routing/routes";
import { useChangeContentEntityCategoryDialog } from "src/content/entities/ChangeContentEntityCategoryDialog";
import { useDeleteContentEntityDialog } from "src/content/entities/DeleteContentEntityDialog";
import { useEditContentEntityDialog } from "src/content/entities/EditContentEntityDialog";

const getExtraMenuItems = entityContentTypeLookup<ReactElement[]>({
    AgendaModule: () => [],
    Company: company => company.fields.website ? [
        <MenuItem key="open" component="a" href={company.fields.website_url} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Visit website</ListItemText>
        </MenuItem>,
    ] : [],
    ContentEntitiesModule: () => [],
    CountdownClockModule: () => [],
    EventTile: eventTile => {
        const clientId = useClientId()!
        return eventTile.fields.event_id ? [
            <MenuItem key="open" component="a" href={link.toEvent(clientId, eventTile.fields.event_id)} target="_blank">
                <ListItemIcon><OpenInNew /></ListItemIcon>
                <ListItemText>View event</ListItemText>
            </MenuItem>,
        ] : []
    },
    EventTilesModule: () => [],
    HeroModule: () => [],
    HighlightsModule: () => [],
    InfoBannerModule: () => [],
    Link: link => [
        <MenuItem key="open" component="a" href={link.fields.url} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>,
    ],
    MarkdownModule: () => [],
    Page: () => [],
    Pdf: pdf => [
        <MenuItem key="open" component="a" href={pdf.fields.file.absoluteUrl} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>,
    ],
    PublicRegistrationsModule: () => [],
    RegistrationModule: () => [],
    RegAndPaymentModule: () => [],
    RemoteHostedPdf: pdf => [
        <MenuItem key="open" component="a" href={pdf.fields.url} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>,
    ],
    RemoteHostedVideo: video => [
        <MenuItem key="open" component="a" href={video.fields.src} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>,
    ],
    Video: video => [
        <MenuItem key="open" component="a" href={video.fields.file.absoluteUrl} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>,
    ],
    VideoLink: video => [
        <MenuItem key="open" component="a" href={video.fields.url} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>,
    ],
    VideoModule: () => [],
    VimeoVideo: video => [
        <MenuItem key="open" component="a" href={video.fields.url} target="_blank">
            <ListItemIcon><OpenInNew /></ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>,
    ],
    Custom: () => [],
})

export type ContentEntityContextMenuProps = LockableMenuProps & {
    entity: ContentEntity
}

const ContentEntityContextMenu: FC<ContentEntityContextMenuProps> = ({
    entity,
    ...props
}) => {
    const editDialog = useEditContentEntityDialog()
    const changeCategoryDialog = useChangeContentEntityCategoryDialog()
    const deleteDialog = useDeleteContentEntityDialog()

    const extraMenuItems = getExtraMenuItems(entity)

    return <LockableMenu
        closeOnClick
        {...props}
    >
        <MenuItem onClick={() => editDialog.open({ entity })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => changeCategoryDialog.open({ entity })}>
            <ListItemIcon><MoveDown /></ListItemIcon>
            <ListItemText>Change category</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteDialog.open({ entity })}>
            <ListItemIcon><DeleteForever color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
        {extraMenuItems.length > 0 && <Divider />}
        {extraMenuItems}
    </LockableMenu>
}

export const useContentEntityContextMenu = createPopupHook({
    element: ContentEntityContextMenu,
    scope: Scope.Event,
})