import { ObjectFieldValueType } from "@marketpartner/backend-api"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { useState } from "react"
import { backend } from "src/common/api"
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns"
import { ValidMergedRegistration } from "src/registrations/import/pre-process/validate-core-properties"
import { PreparedRegistration, prepareForImport } from "src/registrations/import/preparation/prepare-for-import"

export type UsePrepareImportMutationArgs = [
    registrations: ValidMergedRegistration[],
    columnMapping: Record<string, ColumnMatch>
]

export type UsePrepareImportMutation = UseMutationResult<PreparedRegistration[], unknown, UsePrepareImportMutationArgs> & {
    progress: number
}

export const usePrepareImportMutation = (): UsePrepareImportMutation => {
    const [progress, setProgress] = useState(0)

    const uploadMutation = backend.content.useUploadAndProcessRemoteFile()
    
    const preparationMutation = useMutation({
        mutationFn: (args: UsePrepareImportMutationArgs) => prepareForImport(
            ...args,
            async csvValue => {
                const result = await uploadMutation.mutateAsync([csvValue.uploadType, csvValue.url])
                return {
                    type: ObjectFieldValueType.EnhancedUpload,
                    fileId: result.fileId,
                    metadata: {
                        type: csvValue.uploadType,
                    }
                }
            },
            setProgress
        ),
    })

    return {
        ...preparationMutation,
        progress,
    }
}
