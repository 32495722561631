import { Registration, RegistrationCategoryType, Scope } from "@marketpartner/backend-api";
import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useDeleteRegistrationDialog } from "src/registrations/actions/DeleteRegistrationDialog";
import { useEditPrimaryRegistrationDialog } from "src/registrations/actions/EditPrimaryRegistrationDialog";


export type ProfileContextMenuProps = LockableMenuProps & {
    registration: Registration
    restrictCategoryType?: RegistrationCategoryType
}

const ProfileContextMenu: FC<ProfileContextMenuProps> = ({
    registration,
    restrictCategoryType,
    ...props
}) => {
    const editPrimaryRegistrationDialog = useEditPrimaryRegistrationDialog()
    const deleteDialog = useDeleteRegistrationDialog()

    return <LockableMenu closeOnClick {...props}>
        <MenuItem onClick={() => editPrimaryRegistrationDialog.open({ registration: registration as any, restrictCategoryType })}>
            <ListItemIcon><Edit color="primary" /></ListItemIcon>
            <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={() => deleteDialog.open({
            registration,
        })}>
            <ListItemIcon><Delete color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
    </LockableMenu>
}

export const useProfileContextMenu = createPopupHook({
    scope: Scope.Event,
    element: ProfileContextMenu,
})