import { LoadingButton } from "@mui/lab";
import { Button, Fade, Input, Typography } from "@mui/material";
import { FC } from "react";
import { DialogStepper } from "src/common/dialogs/DialogStepper";
import { ProgressWithLabel } from "src/common/loading/ProgressWithLabel";
import { MatchCsvColumns } from "src/registrations/import/column-matching/MatchCsvColumns";
import { useColumnMatches } from "src/registrations/import/column-matching/useColumnMatches";
import { useImportMutation } from "src/registrations/import/import-process/useImportMutation";
import { ColumnMatch } from "src/registrations/import/pre-process/auto-match-columns";
import { useCsvPreprocessor } from "src/registrations/import/pre-process/useCsvPreprocessor";
import { usePrepareImportMutation } from "src/registrations/import/preparation/usePrepareImportMutation";
import { ImportPreviewGrid } from "src/registrations/import/preview/ImportPreviewGrid";
import { ResultCounts } from "src/registrations/import/results/ResultCounts";
import { ResultsGrid } from "src/registrations/import/results/ResultsGrid";
import { SelectCsvFileText } from "src/registrations/import/SelectCsvFileText";

export type ImportRegistrationsDialogProps = {
}

export const ImportRegistrationsDialog: FC<ImportRegistrationsDialogProps> = () => {
    const preprocessor = useCsvPreprocessor()
    const columnMatches = useColumnMatches(
        preprocessor.preprocessedCsv?.columnMatches,
        preprocessor.preprocessedCsv?.formFields ?? []
    )
    const prepareMutation = usePrepareImportMutation()
    const importMutation = useImportMutation()

    const stepIdx = !preprocessor.preprocessedCsv ? 0 :
        prepareMutation.isIdle ? 1 :
            !prepareMutation.isSuccess ? 2 :
                importMutation.isIdle ? 3 :
                    !importMutation.isSuccess ? 4 :
                        5

    const completedColumnMapping = columnMatches.matches as Record<string, ColumnMatch>
    const preparedRegistrations = prepareMutation.data ?? []
    const results = importMutation.data ?? []

    return <DialogStepper
        title="Import registrations"
        stepIdx={stepIdx}
        steps={[
            {
                label: "Select file",
                content: <SelectCsvFileText
                    error={preprocessor.preprocessingError}
                    sx={{ width: "fit-content", mx: "auto" }}
                />,
                nextAction: <SelectFileButton
                    onSelectFile={preprocessor.preprocessCsv}
                    isLoading={preprocessor.isProcessing}
                />,
            },
            {
                label: "Match columns",
                content: <MatchCsvColumns
                    columnMatches={columnMatches}
                    sx={{ maxWidth: 950, mx: "auto" }}
                />,
                nextAction: <>
                    <Fade in={!columnMatches.isComplete}>
                        <Typography color="text.secondary">
                            ({columnMatches.unmatchedCount} remaining)
                        </Typography>
                    </Fade>
                    <Button
                        children="Preview changes"
                        variant="contained"
                        disabled={!columnMatches.isComplete}
                        onClick={() => prepareMutation.mutate([
                            preprocessor.preprocessedCsv!.rows,
                            completedColumnMapping,
                        ])}
                    />
                </>,
                back: preprocessor.reset,
            },
            {
                label: "Upload files",
                content: <ProgressWithLabel
                    percentage={prepareMutation.progress * 100}
                />
            },
            {
                label: "Review",
                content: <ImportPreviewGrid
                    preparedRegistrations={preparedRegistrations}
                    columnMapping={completedColumnMapping}
                />,
                nextAction: <Button
                    variant="contained"
                    onClick={() => importMutation.mutate(preparedRegistrations)}
                >
                    Import
                </Button>,
                back: prepareMutation.reset
            },
            {
                label: "Import",
                content: <ProgressWithLabel
                    percentage={importMutation.processed / preparedRegistrations.length * 100}
                />,
            },
            {
                label: "Results",
                header: <ResultCounts
                    results={results}
                    sx={{ px: 4, mb: 2 }}
                />,
                content: <ResultsGrid
                    columnMapping={completedColumnMapping}
                    registrations={preparedRegistrations}
                    results={results}
                />
            }
        ]}
    />
}

type SelectFileButtonProps = {
    onSelectFile: (file: File) => void
    isLoading: boolean
}

const SelectFileButton: FC<SelectFileButtonProps> = ({
    onSelectFile,
    isLoading,
}) => {
    return <label htmlFor="contained-button-file">
        <Input
            sx={{ display: "none" }}
            inputProps={{ accept: '.csv' }}
            id="contained-button-file"
            type="file"
            onChange={e => onSelectFile((e.target as HTMLInputElement).files![0])}
        />
        <LoadingButton
            variant="contained"
            component="span"
            loading={isLoading}
        >Select CSV</LoadingButton>
    </label>
}
