import { ImportRegistrationsResult, RegistrationType } from "@marketpartner/backend-api"
import { filterType, inParallelBatches } from "@marketpartner/mp-common"
import { MutationOptions, useMutation, UseMutationResult } from "@tanstack/react-query"
import { useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { useEvent } from "src/events/event-context"
import { updatePrimaryRegistrationIds } from "src/registrations/import/import-process/update-primary-registration-ids"
import { PreparedRegistration } from "src/registrations/import/preparation/prepare-for-import"


export type UseImportMutationOptions = Partial<MutationOptions<ImportRegistrationsResult[], unknown, PreparedRegistration[]>>

export type UseImportMutation = UseMutationResult<ImportRegistrationsResult[], unknown, PreparedRegistration[]> & {
    processed: number
}

export const useImportMutation = (
    options?: UseImportMutationOptions
): UseImportMutation => {
    const client = useClient()!
    const event = useEvent()!
    const invalidate = backend.registrations.useInvalidate()
    const importPrimariesMutation = backend.registrations.useImportPrimaryRegistrations({
        disableInvalidation: true,
    })
    const importGuestsMutation = backend.registrations.useImportGuestRegistrations({
        disableInvalidation: true,
    })
    const updateRegistrationsMutation = backend.registrations.useImportUpdatedRegistrations({
        disableInvalidation: true,
    })
    const [processed, setProcessed] = useState(0)

    const doImportActionInParallelBatches = async <T>(
        registrations: T[],
        importAction: (batch: T[]) => Promise<ImportRegistrationsResult[]>,
    ) => {
        const results = await inParallelBatches(registrations, importAction, {
            maxBatchSize: 100,
            progressListener: ({ deltaProcessed }) => {
                setProcessed(processed => processed + deltaProcessed)
            }
        })
        return results.results.flat()
    }

    const importRequest = useMutation({
        mutationFn: async (registrations: PreparedRegistration[]) => {
            const results: ImportRegistrationsResult[] = []

            // Insert primary registrations
            const primaryInserts = filterType(registrations, RegistrationType.Primary as const)
                .filter(it => !it.existingRegistration)
            const insertPrimaryResults = await doImportActionInParallelBatches(
                primaryInserts,
                batch => importPrimariesMutation.mutateAsync([client.id, event.id, batch])
            )
            results.push(...insertPrimaryResults)

            // Insert guests
            const guestInserts = filterType(
                updatePrimaryRegistrationIds(registrations, insertPrimaryResults),
                RegistrationType.Guest as const
            ).filter(it => !it.existingRegistration)
            results.push(...await doImportActionInParallelBatches(
                guestInserts,
                batch => importGuestsMutation.mutateAsync([client.id, event.id, batch])
            ))

            // Update registrations
            const updates = registrations
                .filter(it => it.existingRegistration !== undefined)
                .map(it => ({
                    ...it,
                    id: it.existingRegistration!.id
                }))
            results.push(...await doImportActionInParallelBatches(
                updates,
                batch => updateRegistrationsMutation.mutateAsync([client.id, event.id, batch])
            ))

            return results
        },
        ...options,
        onSettled: async (results, error, variables, context) => {
            await invalidate()
            await options?.onSettled?.(results, error, variables, context)
        },
    })

    return {
        ...importRequest,
        processed,
    }
}
