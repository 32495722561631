import { ScheduledEmail, ScheduledJobExecutionState } from "@marketpartner/backend-api"
import { Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useRegistrationConditionCount } from "src/email/scheduling/useRegistrationConditionCount"
import { NameAndSubtitle } from "src/registrations/NameAndSubtitle"


export type ScheduledEmailRecipientCountProps = {
    email: ScheduledEmail
}

export const ScheduledEmailRecipientCount: FC<ScheduledEmailRecipientCountProps> = ({
    email
}) => {
    const estimatedCount = useRegistrationConditionCount(email.filter)
    const state = email.scheduledJob?.state

    if (state === ScheduledJobExecutionState.Error || state === ScheduledJobExecutionState.Success) {
        return <Stack>
            <Typography>{email.successCount}</Typography>
            {email.errorCount > 0 && <Typography>Failed: {email.errorCount}</Typography>}
        </Stack>
    }
    return <NameAndSubtitle
        name={estimatedCount}
        subtitle="(estimate)"
    />
}